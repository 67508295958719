<template>
  <div class="create-perk">
    <EntryPermissionAppbar />
    <div class="ma-4">
      <EntryPermissionForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create EntryPermission
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import EntryPermissionAppbar from '@/views/Home/Maintenance/EntryPermission/components/EntryPermissionAppbar'
import EntryPermissionForm from '@/views/Home/Maintenance/EntryPermission/components/EntryPermissionForm'

export default {
  components: {
    EntryPermissionAppbar,
    EntryPermissionForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'entryPermission/clearEntryPermissionDetails',
    }),
  },
}
</script>
