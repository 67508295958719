<template>
  <div class="entryPermission-details py-8">
    <EntryPermissionAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <div class="ma-4">
          <div class="mb-6">
            <v-row>
              <v-col cols="6" md="4">
                <ViewingTitle :title="displayName" />
              </v-col>
              <v-col cols="6" md="2">
                <DeleteOrRestore
                  v-if="hasDeletePermissions"
                  :model="entryPermission"
                  name="EntryPermission"
                  redirect="/maintenance/entry-permissions"
                />
              </v-col>
            </v-row>

            <EntryPermissionForm
              class="entryPermission-details__form"
              :entryPermission="entryPermission"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View EntryPermission Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import EntryPermissionAppbar from '@/views/Home/Maintenance/EntryPermission/components/EntryPermissionAppbar'
import EntryPermissionForm from '@/views/Home/Maintenance/EntryPermission/components/EntryPermissionForm'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ViewingTitle from '@/components/common/ViewingTitle.vue'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore/index.vue'

export default {
  name: 'EntryPermissionDetails',

  components: {
    DeleteOrRestore,
    ViewingTitle,
    EntryPermissionAppbar,
    EntryPermissionForm,
    Loading,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      entryPermission: (state) => state.entryPermission.entryPermissionDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.entryPermission ? this.entryPermission.label : null
    },

    hasDeletePermissions() {
      return validatePermissions([PERMISSION.TAGS_DELETE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getEntryPermissionDetails: 'entryPermission/getEntryPermissionDetails',
    }),

    ...mapMutations({
      clearEntryPermissionDetails:
        'entryPermission/clearEntryPermissionDetails',
    }),

    async getEntryPermission() {
      this.loading = true
      await this.getEntryPermissionDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getEntryPermission()
  },

  destroyed() {
    this.clearEntryPermissionDetails()
  },

  watch: {
    $route() {
      this.getEntryPermission()
    },
  },
}
</script>

<style lang="scss" scoped>
.entryPermission-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
